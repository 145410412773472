<template>
	<div class="container-box">
		<div class="video-box">
			<div v-for="(item,index) in list" :key="index" class="item-box">
				<!-- <video v-if="item.sourceType==1" class="video-item" :src="item.src" controls :poster="item.poster" ></video> -->
				<img @click="toLink(item)" :src="item.poster" class="video-item" />
			</div>

		</div>
	</div>
</template>

<script>
	import dump from "../util/dump.js";
	export default {
		props: {
			list: {
				type: Array,
				required: true
			}
		},
		mounted() {
			// console.log(this.list)
		},
		data() {
			return {

			}

		},
		methods: {
			toLink(item) {
				dump.link({
          type: 3,
          link: item.link
        });
			}
		}
	}
</script>

<style scoped="scoped">
	.container-box {
		max-width: 100vw;
		display: flex;

	}

	.video-box {
		display: flex;
		width: 100%;
		margin: 0 auto;
	}

	.item-box {
		width: 30%;
		/* width: 19.8vw; */
		height: 9.8vw;
	}

	.video-item {
		max-width: 100%;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	.video-box :not(:first-child) {
		padding-left: 0.5vw;
	}

	@media (min-width: 768px) {
		.container-box {
			padding: 1.125rem 0.896% 1.75rem 0.896%;
		}
	}

	@media (min-width: 1200px) {
		.container-box {
			padding: 1.125rem 0.896% 3.75rem 0.896%;
		}

	}
</style>
